import React from "react";
import { Routes, Route } from "react-router-dom";
import Error404 from "./Pages/Error404";
import Construction from "./Home/Construction";
import AboutUs from "./Pages/Aboutus";
import OurServices from "./Pages/Leadership";
import ContactUs from "./Contact-page/Contactus";
import Portfolio from "./Portfolio-page/Portfolio-single";

const MainNavigation = () => {
  return (
    <>
      <>
        <Routes>
          <Route path="/" exact={true} element={<Construction />} />
          <Route path="/about-us" exact={true} element={<AboutUs />} />
          <Route path="/our-services" exact={true} element={<OurServices />} />
          <Route path="/portfolio" exact={true} element={<Portfolio />} />
          <Route path="/contact-us" exact={true} element={<ContactUs />} />
          <Route element={<Error404 />} />
        </Routes>
      </>
    </>
  );
};

export default MainNavigation;
