import React from "react";

const Nav = () => {
  return (
    <>
      <header id="pt-header" className="pt-header-style-1 pt-has-sticky">
        <div className="pt-bottom-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <a className="navbar-brand" href="/">
                    <img className="img-fluid logo" src="assets/images/logo.png" alt="logo" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div id="pt-menu-contain" className="pt-menu-contain">
                      <ul id="pt-main-menu" className="navbar-nav ml-auto">
                        <li className="menu-item">
                          <a href="/" className="nav-item" aria-current="page">
                            Home
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="/our-services" className="nav-item" aria-current="page">
                            Services
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="/about-us" className="nav-item" aria-current="page">
                            About Us
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="/portfolio" className="nav-item" aria-current="page">
                            Portfolio
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="/contact-us" className="nav-item" aria-current="page">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pt-header-info-box">
                    <div className="pt-header-call">
                      <a href="!tel:4253167171">
                        <i className="ion-ios-telephone"></i>
                        <div className="pt-call-info">
                          <h5>425-316-7171</h5>
                          <span>Make A call </span>
                        </div>
                      </a>
                    </div>
                    <div className="pt-btn-container">
                      <a href="/contact-us" className="pt-button">
                        <div className="pt-button-block">
                          <span className="pt-button-line-left"></span>
                          <span className="pt-button-text">Get Quote</span>
                          <span className="pt-button-line-right"></span>
                          <i className="ion ion-android-arrow-dropright pt-btn-icon"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Nav;
