import React from "react";

const ScrollTop = () => {
	return (
		<>
		<div id="back-to-top">
		<a className="topbtn" id="top" href="#top"> <i className="ion-ios-arrow-up"></i> </a>
	 </div>
	 </>
	);
};
export default ScrollTop;
