import React from "react";

const Banner = () => {
  const style1 = {
    background: "transparent",
    padding: "0",
    margin: "0px auto",
    marginTop: "0",
    marginBottom: "0",
  };
  const style2 = {
    zIndex: "10",
    fontFamily: "Jost, sansserif",
    textTransform: "capitalize",
  };
  const style3 = {
    zIndex: "11",
    fontFamily: "Jost, sansserif",
  };
  const style4 = {
    zIndex: "9",
    backgroundColor: "#a82424",
    fontFamily: "Jost, sansserif",
  };
  const style5 = {
    zIndex: "9",
    backgroundColor: "#a82424",
    fontFamily: "Jost, sansserif",
  };
  const style6 = {
    zIndex: "8",
    backgroundColor: "#rgba(0,33,85,0)",
    fontFamily: "Jost, sansserif",
  };
  const style7 = {
    zIndex: "10",
    textTransform: "capitalize",
    fontFamily: "Jost, sansserif",
  };
  const style8 = {
    zIndex: "12",
    fontFamily: "Jost, sansserif",
  };
  const style9 = {
    zIndex: "8",
    backgroundColor: "rgba(255,255,255,0)",
    fontFamily: "Jost, sansserif",
  };
  const style10 = {
    zIndex: "12",
    textTransform: "capitalize",
    fontFamily: "Jost, sansserif",
  };
  return (
    <>
      <p className="rs-p-wp-fix"></p>
      <rs-module-wrap
        id="rev_slider_4_1_wrapper"
        data-alias="slider-1"
        data-source="gallery"
        style={style1}
      >
        <rs-module id="rev_slider_4_1" style={{ display: "none" }} data-version="6.2.2">
          <rs-slides>
            <rs-slide
              data-key="rs-11"
              data-title="Slide"
              data-thumb="assets/rev-slider/assets/62-50x100.jpg"
              data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;"
            >
              <img
                src="assets/rev-slider/assets/62.jpg"
                alt="logo-1"
                title="62"
                width="1920"
                height="1080"
                className="rev-slidebg"
                data-no-retina
              />

              <rs-layer
                id="slider-4-slide-11-layer-2"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:-17px,-33px,0,0;"
                data-text="w:normal;s:92,72,62,42;l:92,72,62,42;fw:600;a:right;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style2}
              >
                Building The Future
                <br />
                Restoring The Past
              </rs-layer>

              <rs-layer
                id="slider-4-slide-11-layer-3"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:108px,85px,48px,39px;"
                data-text="w:normal;s:16,16,16,11;l:32,32,32,22;a:right;"
                data-dim="minh:0px,none,none,none;"
                data-vbility="t,t,f,f"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:1200;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style3}
              >
                It is a long established fact that a reader will be distracted by the readable
                content of a<br />
                page when looking at its layout.
              </rs-layer>
              {/* <!--
                     --> */}
              <a href="/about-us">
                <rs-layer
                  id="slider-4-slide-11-layer-4"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:204px,170px,125px,83px;"
                  data-text="w:normal;s:16,16,16,18;l:32,25,32,30;a:center;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1500;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#a82424;bgc:#fff;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                  style={style4}
                >
                  Explore More
                  <br />
                </rs-layer>
              </a>
              {/* <!--  
                     --> */}
              <rs-layer
                id="slider-4-slide-11-layer-5"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:-137px,-127px,-90px,-67px;"
                data-text="w:normal;s:16,16,16,18;l:30,32,32,24;fw:600;"
                data-padding="l:10,8,6,4;"
                data-border="bos:solid;boc:#ff631b;bow:0,0,0,2px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:600;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style5}
              >
                Great Experience in Bulding.
              </rs-layer>

              <a href="/about-us">
                {" "}
                <rs-layer
                  id="slider-4-slide-11-layer-6"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="x:r;xo:240px,210px,177px,159px;y:m;yo:204px,170px,125px,83px;"
                  data-text="w:normal;s:16,16,16,18;l:32,25,32,24;a:center;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:10,8,6,4;r:32,25,19,12;b:10,8,6,4;l:32,25,19,12;"
                  data-border="bos:solid;boc:#ffffff;bow:2px,2px,2px,2px;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1800;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#ff5e14;bgc:rgba(255,94,20,0);boc:#ff5e14;bor:0px,0px,0px,0px;bos:solid;bow:2px,2px,2px,2px;sp:200;e:power1.inOut;"
                  style={style6}
                >
                  Get In Touch
                  <br />
                </rs-layer>
              </a>
            </rs-slide>
            <rs-slide
              data-key="rs-10"
              data-title="Slide"
              data-thumb="assets/rev-slider/assets/61-50x100.jpg"
              data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;"
            >
              <img
                src="assets/rev-slider/assets/61.jpg"
                alt="architek"
                title="61"
                width="1920"
                height="1080"
                className="rev-slidebg"
                data-no-retina
              />

              <rs-layer
                id="slider-4-slide-10-layer-2"
                data-type="text"
                data-rsp_ch="on"
                data-xy="xo:50px,40px,30px,15px;y:m;yo:-17px,-13px,0,3px;"
                data-text="w:normal;s:92,72,56,42;l:92,72,60,48;fw:600;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style7}
              >
                Provide Effective
                <br />
                Solutions.
              </rs-layer>
              {/* <!--
                     --> */}
              <rs-layer
                id="slider-4-slide-10-layer-3"
                data-type="text"
                data-rsp_ch="on"
                data-xy="xo:50px,40px,30px,15px;y:m;yo:118px,102px,28px,37px;"
                data-text="w:normal;s:16,16,16,11;l:32,32,32,22;"
                data-dim="minh:0px,none,none,none;"
                data-vbility="t,t,f,f"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:1200;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style3}
              >
                It is a long established fact that a reader will be distracted by the readable
                content of a<br />
                page when looking at its layout.
              </rs-layer>
              {/* <!--
                     --> */}
              <a href="/about-us">
                <rs-layer
                  id="slider-4-slide-10-layer-4"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="xo:50px,40px,30px,15px;y:m;yo:214px,188px,105px,84px;"
                  data-text="w:normal;s:16,12,16,18;l:32,25,32,30;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1500;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#ff631b;bgc:#fff;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                  style={style4}
                >
                  Explore More
                </rs-layer>
              </a>
              {/* <!--  z-index:9;background-color:#ff5e14;font-family:Jost, sans-serif;
                     --> */}

              <rs-layer
                id="slider-4-slide-10-layer-5"
                data-type="text"
                data-rsp_ch="on"
                data-xy="xo:50px,40px,30px,15px;y:m;yo:-127px,-110px,-80px,-64px;"
                data-text="w:normal;s:16,16,16,18;l:30,32,26,24;fw:600;"
                data-padding="l:10,8,6,4;"
                data-border="bos:solid;boc:#ff631b;bow:0,0,0,2px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:600;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style10}
              >
                Great Experience in Bulding.
              </rs-layer>
              {/* <!--
                     --> */}
              <a href="/about-us">
                <rs-layer
                  id="slider-4-slide-10-layer-6"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="xo:240px,190px,193px,164px;y:m;yo:214px,188px,105px,84px;"
                  data-text="w:normal;s:16,12,16,18;l:32,25,32,30;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:10,8,6,4;r:32,25,19,12;b:10,8,6,4;l:32,25,19,12;"
                  data-border="bos:solid;boc:#ffffff;bow:2px,2px,2px,2px;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1800;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#ff631b;bgc:rgba(255,94,20,0);boc:#ff631b;bor:0px,0px,0px,0px;bos:solid;bow:2px,2px,2px,2px;sp:200;e:power1.inOut;"
                  style={style9}
                >
                  Get In Touch
                  <br />
                </rs-layer>
              </a>
              {/* <!--    style="z-index:8;background-color:rgba(255,255,255,0);font-family:Jost, sans-serif;"
                     --> */}
            </rs-slide>
            <rs-slide
              data-key="rs-12"
              data-title="Slide"
              data-thumb="assets/rev-slider/assets/63-50x100.jpg"
              data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;"
            >
              <img
                src="assets/rev-slider/assets/63.jpg"
                alt="architek"
                title="63"
                width="1920"
                height="1080"
                className="rev-slidebg"
                data-no-retina
              />
              {/* <!--
                     --> */}
              <rs-layer
                id="slider-4-slide-12-layer-2"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:c;xo:0,10px,30px,15px;y:m;yo:-17px,-13px,0,0;"
                data-text="w:normal;s:92,72,72,50;l:92,72,72,50;fw:600;a:center;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:900;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style7}
              >
                BUILD A BETTER
                <br />
                world
              </rs-layer>
              {/* <!--   "z-index:10;font-family:Jost, sans-serif;text-transform:uppercase;"
                     --> */}
              <rs-layer
                id="slider-4-slide-12-layer-3"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:c;xo:0,10px,30px,15px;y:m;yo:108px,105px,76px,54px;"
                data-text="w:normal;s:16,16,16,11;l:32,32,32,24;a:center;"
                data-dim="minh:0px,none,none,none;"
                data-vbility="t,t,f,f"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:1200;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style8}
              >
                It is a long established fact that a reader will be distracted by the readable
                content of a<br />
                page when looking at its layout.
              </rs-layer>
              {/* <!--
                     --> */}
              <a href="/about-us">
                <rs-layer
                  id="slider-4-slide-12-layer-4"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="x:c;xo:-80px,-90px,-70px,-85px;y:m;yo:204px,190px,125px,93px;"
                  data-text="w:normal;s:16,16,16,18;l:32,25,32,32;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1500;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#ff5e14;bgc:#fff;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                  style={style4}
                >
                  Explore More
                </rs-layer>
              </a>
              {/* <!--     style="z-index:9;background-color:#ff5e14;font-family:Jost, sans-serif;"
                     --> */}
              <rs-layer
                id="slider-4-slide-12-layer-5"
                data-type="text"
                data-rsp_ch="on"
                data-xy="x:c;xo:0,10px,30px,15px;y:m;yo:-137px,-107px,-100px,-74px;"
                data-text="w:normal;s:16,16,16,18;l:30,23,32,24;fw:600;"
                data-padding="l:10,8,6,4;"
                data-border="bos:solid;boc:#ff5e14;bow:0,0,0,2px;"
                data-frame_0="x:50,39,29,17;"
                data-frame_1="st:600;sp:1000;"
                data-frame_999="o:0;st:w;"
                style={style10}
              >
                Great Experience in Bulding.
              </rs-layer>
              {/* <!--
                     --> */}
              <a href="/about-us">
                <rs-layer
                  id="slider-4-slide-12-layer-6"
                  className="rev-btn"
                  data-type="button"
                  data-rsp_ch="on"
                  data-xy="x:c;xo:110px,99px,90px,74px;y:m;yo:204px,190px,125px,95px;"
                  data-text="w:normal;s:16,16,16,18;l:32,25,32,32;"
                  data-dim="minh:0px,none,none,none;"
                  data-padding="t:10,8,6,4;r:32,25,19,12;b:10,8,6,4;l:32,25,19,12;"
                  data-border="bos:solid;boc:#ffffff;bow:2px,2px,2px,2px;"
                  data-frame_0="x:50,39,29,17;"
                  data-frame_1="st:1800;sp:1000;"
                  data-frame_999="o:0;st:w;"
                  data-frame_hover="c:#ff5e14;bgc:rgba(255,94,20,0);boc:#ff5e14;bor:0px,0px,0px,0px;bos:solid;bow:2px,2px,2px,2px;sp:200;e:power1.inOut;"
                  style={style6}
                >
                  Get In Touch
                  <br />
                </rs-layer>
              </a>
            </rs-slide>
          </rs-slides>
          <rs-progress
            className="rs-bottom"
            style={{ visibility: "hidden !important" }}
          ></rs-progress>
        </rs-module>
      </rs-module-wrap>
    </>
  );
};
export default Banner;
