import React from "react";

const Services = () => {
  return (
    <>
      <section className="service wow animate__fadeIn" data-wow-delay="0.2s">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="pt-service-box pt-service-box-1">
                <div className="pt-service-banner">
                  <img className="img-fluid" src="assets/images/blog/blog-6.jpg" alt="servicebox" />
                  <div className="pt-service-media">
                    <i className=" flaticon-008-factory"></i>
                  </div>
                </div>
                <div className="pt-service-box-info">
                  <h4 className="pt-service-title">Our Mission</h4>
                  <p className="pt-service-description">
                    Lorem Ipsum is simply dummy text of the printing Ipsum has been the standard .
                  </p>
                  <a className="pt-button pt-btn-link" href="/our-services">
                    <div className="pt-button-block ">
                      <span className="pt-button-line-left"></span>
                      <span className="pt-button-text">Read more</span>
                      <span className="pt-button-line-right"></span>
                      <i className="ion ion-android-arrow-dropright pt-btn-icon"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="pt-service-box pt-service-box-1">
                <div className="pt-service-banner">
                  <img className="img-fluid" src="assets/images/blog/blog-3.jpg" alt="servicebox" />
                  <div className="pt-service-media">
                    <i className=" flaticon-050-robot-arm"></i>
                  </div>
                </div>
                <div className="pt-service-box-info">
                  <h4 className="pt-service-title">Our Vision</h4>
                  <p className="pt-service-description">
                    Lorem Ipsum is simply dummy text of the printing Ipsum has been the standard .
                  </p>
                  <a className="pt-button pt-btn-link" href="/our-services">
                    <div className="pt-button-block ">
                      <span className="pt-button-line-left"></span>
                      <span className="pt-button-text">Read more</span>
                      <span className="pt-button-line-right"></span>
                      <i className="ion ion-android-arrow-dropright pt-btn-icon"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-5">
              <div className="pt-service-box pt-service-box-1">
                <div className="pt-service-banner">
                  <img className="img-fluid" src="assets/images/blog/blog-2.jpg" alt="servicebox" />
                  <div className="pt-service-media">
                    <i className=" flaticon-023-pin"></i>
                  </div>
                </div>
                <div className="pt-service-box-info">
                  <h4 className="pt-service-title">Our Values</h4>
                  <p className="pt-service-description">
                    Lorem Ipsum is simply dummy text of the printing Ipsum has been the standard .
                  </p>
                  <a className="pt-button pt-btn-link" href="/our-services">
                    <div className="pt-button-block ">
                      <span className="pt-button-line-left"></span>
                      <span className="pt-button-text">Read more</span>
                      <span className="pt-button-line-right"></span>
                      <i className="ion ion-android-arrow-dropright pt-btn-icon"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Services;
